// AppRouter.js

import { Route, BrowserRouter as Router } from 'react-router-dom';
import HomePage from './pages/HomePage'; // 仅导入 HomePage

function AppRouter() {
  return (
    <Router>
      <Route path="/" component={HomePage} />
    </Router>
  );
}

export default AppRouter;
