import axios from 'axios';

// 定义后端API的URL
const API_BASE_URL = 'https://api.delistudio.top/user-detail';

// 使用Axios来发起请求
const instance = axios.create({ baseURL: API_BASE_URL });

// 获取所有用户详情（带分页）
export const getAllUserDetails = (headers, pageNum, pageSize) => {
  console.log('headers:', headers);

  // 创建查询参数对象
  const params = new URLSearchParams({
    page: pageNum,
    limit: pageSize,
  });

  return instance.get(`/user-detail-list?${params.toString()}`, { headers: headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching all user details:', error);
      throw error;
    });
};


// 创建用户详情
export function createUserDetail(url, userDetail, customHeaders) {
  return axios.post(url, userDetail, { headers: customHeaders })//headers一定要这样提交
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error creating user detail:', error);
      throw error;
    });
}
