import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GetCookie from "../utils/GetCookie";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

const UpdateUserDetail = () => {
  useEffect(() => {
    document.title = "嘉有儿女 - 更新用户详情";
  }, []);

  const history = useHistory();
  const { username } = useParams();
  console.log("UpdateUserDetailPage:", username);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (username === "null") {
      message.warning("请从用户详情列表进入该页面");
      setLoading(false); // 设置加载状态为 false，允许页面渲染
      return; // 如果 id 不存在，不继续执行后续逻辑
    }

    // 使用userId获取用户的当前数据，仅当 id 存在时才执行
    if (username) {
      const fetchUserData = async () => {
        const token = GetCookie.getCookie("token");
        if (!token) {
          history.push("/login");
          return;
        }
        try {
          const response = await axios.get(
            `https://api.delistudio.top/user-detail/${username}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          console.log("response:", response);
          form.setFieldsValue(response.data);
          setLoading(false);
        } catch (error) {
          message.error("获取用户数据失败");
        }
      };

      fetchUserData();
    } else {
      setLoading(false); // 没有 id 参数时，直接设置加载完成
    }
  }, [username, form]);

  const onFinish = async (values) => {
    try {
      // 仅当 id 存在时才执行更新操作
      if (username) {
        const token = GetCookie.getCookie("token");
        if (!token) {
          history.push("/login");
          return;
        }
        const updatedResponse = await axios.put(
          `https://api.delistudio.top/user-detail/${username}`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("updatedResponse:", updatedResponse);
        message.success("用户信息更新成功");
      } else {
        // 可以在这里处理没有 id 参数的情况
        message.warning("没有提供 ID，无法更新用户信息");
      }
    } catch (error) {
      message.error("用户信息更新失败");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>更新用户详情</h1>
      <Form {...layout} form={form} name="userDetailForm" onFinish={onFinish}>
        <Form.Item
          label="用户名"
          name="username"
          rules={[{ required: true, message: "Please input the username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="真实姓名"
          name="realName"
          rules={[{ required: true, message: "Please input the real name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="电话号码"
          name="phone"
          rules={[{ required: true, message: "Please input the phone!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="调理项目" name="projects">
          <Input />
        </Form.Item>

        <Form.Item label="邮箱" name="email">
          <Input />
        </Form.Item>

        <Form.Item label="会员卡余额" name="balance">
          <Input />
        </Form.Item>

        <Form.Item label="总次数" name="count">
          <Input />
        </Form.Item>

        <Form.Item label="备注" name="description">
          <Input />
        </Form.Item>

        {/* 添加更多字段的表单项 */}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            更新用户信息
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateUserDetail;
