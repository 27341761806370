import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../services/UserService';

const LoginPage = () => {
    useEffect(() => {
        document.title = '嘉有儿女 - 登录';
    }, []);

    const history = useHistory();

    const handleSubmit = async (values) => {
        try {
            const result = await login(values);
            // 处理登录成功的情况，例如跳转到其他页面
            history.push({
                pathname: '/', // 设置正确的路径
                state: { isLoggedIn: true },
            });
            // 将 token 设置到 cookie 中
            document.cookie = `token=${result.token.token}`;
            // 保存登录状态到 localStorage
            localStorage.setItem('isLoggedIn', 'true');
            message.success('登录成功');
        } catch (err) {
            // 处理登录失败情况
            console.error('登录失败:', err.response.data.msg);
            message.error(err.response.data.msg);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%', // 让页面铺满整个视窗高度
            }}
        >
            <div style={{ width: '100%', maxWidth: '400px' }}>
                <h1 style={{ textAlign: 'center' }}>登录</h1>
                <Form name="basic" onFinish={handleSubmit}>
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[
                            { required: true, message: '请输入用户名!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            { required: true, message: '请输入密码!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ display: 'block', margin: '0 auto' }}
                        >
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;