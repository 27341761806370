import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const DefaultPage = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%', // 让页面铺满整个视窗高度
        }}>
            <Title style={{ color: '#4CAF50' }}>欢迎来到嘉有儿女</Title>
            <Paragraph style={{ color: '#4CAF50', fontSize: '1.5em' }}>
                专业小儿推拿服务，呵护宝宝健康成长
            </Paragraph>
            
            {/* 视频部分 */}
            <div style={{ marginTop: '20px', maxWidth: '800px' }}>
                <img 
                    src={require('../home_img.jpg')} 
                    alt="Home" 
                    style={{ width: '100%', borderRadius: '10px' }} 
                />
            </div>
        </div>
    );
};

export default DefaultPage;